import { FunctionComponent, ReactElement } from "react";

import { Button, Icon, styled } from "@mui/material";

import { Title } from "@mui/icons-material";

import { Link } from "react-router-dom";

import {
    CircularLoader,
    EmptyRecords,
    WorkspaceToolbar,
} from "../../components/common";
import { useListAllLinks } from "../../sdk/hooks";

import { CreateFromFileMenu } from "./CreateFromFileMenu";
import { LinksDataGrid } from "./LinksDataGrid";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Content = styled("div")`
    padding: ${({ theme }) => theme.spacing(2)};
`;

const ToolbarActionsContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const ViewLinksScreen: FunctionComponent = (): ReactElement => {
    const listAllQuery = useListAllLinks(["listAllLinks"], {});

    if (listAllQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <WorkspaceToolbar
                title="Links"
                enableSearch={false}
                actions={
                    <ToolbarActionsContainer>
                        <Link to="/links/new/edit">
                            <Button
                                size="small"
                                variant="contained"
                                disableElevation={true}
                                endIcon={<Icon>add_circle</Icon>}
                            >
                                Create
                            </Button>
                        </Link>
                        <CreateFromFileMenu />
                    </ToolbarActionsContainer>
                }
            />

            {listAllQuery.data?.records?.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>Manage links</Title>
                        <Link to="/links/new/edit">
                            <Button variant="contained">Create link</Button>
                        </Link>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}

            <Content>
                <LinksDataGrid rows={listAllQuery.data?.records ?? []} />
            </Content>
        </Root>
    );
};
