import {
    FunctionComponent,
    MouseEventHandler,
    ReactElement,
    useCallback,
} from "react";

import { Chip, Icon, Link, styled } from "@mui/material";
import Box from "@mui/material/Box";

import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import { ILink } from "../../sdk/types";
import { formatDate } from "../../sdk/utils/numbers";

const AliasContainer = styled("div")`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

const Tags = styled("div")`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

export interface ILinksDataGridProps {
    rows: ILink[];
}

export const LinksDataGrid: FunctionComponent<ILinksDataGridProps> = (
    props: ILinksDataGridProps,
): ReactElement => {
    const { rows } = props;

    const navigate = useNavigate();

    const handleEdit = useCallback(
        (id: string) => () => {
            navigate(`/links/${id}/edit`);
        },
        [navigate],
    );

    // const handleDelete = useCallback((id: string) => () => {}, []);

    const columns: GridColDef[] = [
        {
            field: "alias",
            headerName: "Alias",
            width: 500,
            editable: false,
            renderCell: (params) => {
                const aliasUrl = `${
                    process.env.REACT_APP_LINK_BASE_URL ??
                    "https://links.ifeelblanko.com"
                }/${params.row.alias}`;

                const handleClick: MouseEventHandler<HTMLAnchorElement> = (
                    event,
                ) => {
                    window.open(aliasUrl, "_blank");
                    event.stopPropagation();
                    event.preventDefault();
                };

                return (
                    <AliasContainer>
                        <Link
                            onClick={handleClick}
                            href={aliasUrl}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                textUnderlineOffset: 4,
                            }}
                        >
                            {aliasUrl}&nbsp;
                            <Icon
                                sx={{ ml: 0.5, fontSize: 14 }}
                                fontSize="small"
                            >
                                open_in_new
                            </Icon>
                        </Link>
                    </AliasContainer>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 200,
            editable: true,
        },
        {
            field: "createdAt",
            headerName: "Created at",
            type: "number",
            width: 110,
            editable: true,
            valueGetter: (params: GridValueGetterParams) =>
                formatDate(params.row.createdAt),
        },
        {
            field: "clicks",
            headerName: "Clicks",
            type: "number",
            width: 110,
            editable: false,
        },
        {
            field: "scans",
            headerName: "Scans",
            type: "number",
            width: 110,
            editable: false,
        },
        {
            field: "tags",
            headerName: "Tags",
            description: "The tags associated with the URL.",
            sortable: false,
            width: 400,
            renderCell: (params) => (
                <Tags>
                    {params.row.tags.split(",").map((tag: string) => (
                        <Chip id={tag} label={tag} size="small" color="info" />
                    ))}
                </Tags>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            type: "actions",
            width: 110,
            editable: false,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<Icon>edit</Icon>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEdit(params.row.id)}
                        color="inherit"
                    />,
                    // <GridActionsCellItem
                    //     icon={<Icon>delete</Icon>}
                    //     label="Delete"
                    //     onClick={handleDelete(params.row.id)}
                    //     color="inherit"
                    // />,
                ];
            },
        },
    ];

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/links/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 20,
                    },
                },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[20]}
            checkboxSelection={false}
            disableRowSelectionOnClick={true}
        />
    );
};
