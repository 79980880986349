import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "..";
import { IExternalListPage, ILink } from "../../types";
import { api } from "../../utils";

export interface IListAllLinksParameter {}

/**
 * Lists all the links.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export const useListAllLinks = (
    queryKey: string | string[],
    parameters: IListAllLinksParameter,
): UseQueryResult<IExternalListPage<ILink>, unknown> => {
    const auth = useAuth();

    return useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch links without authorization token.",
                );
            }

            return await api.Link.listAll({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
